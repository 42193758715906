import Vue from 'vue';
import Router from 'vue-router';
const Home = () => import(/* webpackChunkName: "Homescreen" */ '@/pages/play/simple-machines/index.vue');
const NotFound = () => import(/* webpackChunkName: "Welcome" */ '@/pages/error/404.vue');

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [  
      { 
        path: '*', 
        redirect: '/404' 
      }, 
      {
        path: '/404',
        name: '404',
        component: NotFound,
        meta: {
          layout: 'home',
        }
      },
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
          layout: 'home',
        }
      }     
  ]
})

export default router