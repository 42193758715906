import Vue from 'vue';
import App from './App.vue';
import VueGtag from "vue-gtag";
import router from './routes/index';
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import {i18n} from './services/i18n'
import './assets/scss/app.scss';
import MasterLayout from '@/layouts/master';
import mixin from '@/common/mixins.js';
import { ButtonPlugin } from 'bootstrap-vue'

Vue.config.productionTip = false;
Vue.use(VueGtag, {config: { id: process.env.VUE_APP_GA_ID, params: {send_page_view: false }}, useDebugger: false}, ); //process.env.VUE_APP_FIREBASE_MEASUREMENTID
Vue.component('MasterLayout', MasterLayout);
Vue.mixin(mixin);
Vue.use(ButtonPlugin);

Sentry.init({
  Vue,
  dsn: "https://501bb2b0ddb04b9d982e3a37b37eaa1f@o491956.ingest.sentry.io/6522771",
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  release: "simple-machines-@" + process.env.PACKAGE_VERSION,
  environment: process.env.VUE_APP_ENV,
  autoSessionTracking: true,
  integrations: [new TracingIntegrations.BrowserTracing()],
  logErrors: true,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
Vue.prototype.$sentry = Sentry;

let app
  if (!app) {
    app = new Vue({
      router,
      Sentry,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }