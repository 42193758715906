import Vue from 'vue'; 

export default {
    computed: {
 
    },    
    created: function () {
       
    },
    methods: {
      tracking(data) {
      },
        
    }
 }