<template>
  <div id="App">
    <MasterLayout>
      <router-view />
    </MasterLayout>
  </div>
</template>

<script>
// loads up the master layout by default.
export default {
  name: 'App',
}
</script>